import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2, SectionWrapper } from 'styles/Typography.styled';
import * as Common from 'components/_universal/Common';

export const ContentWrapper = styled(SectionWrapper)`
    flex-direction: column;
    padding: 0 20px;
    position: relative;
`;

export const PaginationItem = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        color: ${colors.text};
        cursor: pointer;
        display: flex;
        font-size: 16px;
        width: auto;

        ${mediaQueries.xs} {
            span {
                display: none;
            }
        }
    `,
);

export const Arrow = styled.img`
    display: inline-block;
    margin: 0 0 0 10px;
    position: relative;
    right: 0;
`;

export const ArrowDisabled = styled(Arrow)`
    margin: 0 0 0 10px;
    filter: grayscale(1);
    opacity: 0.4;
`;

export const PrevArrowDisabled = styled(ArrowDisabled)`
    transform: rotate(180deg);
    margin: 0 10px 0 0;
`;

export const PrevArrow = styled(Arrow)`
    margin: 0 10px 0 0;
`;

export const PostDate = styled.p(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f14};
            line-height: 34px;
            margin-bottom: 16px;
        `,
);

export const PostTitle = styled(H2)`
    line-height: 1.4;
    margin: 8px 0;
`;

export const PostTags = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.medium};
        `,
);

export const PostPageContent = styled.div(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        color: ${colors.text};
        position: relative;
        font-family: ${fontFamily.primary};

        p {
            font-size: 14px;
            line-height: 1.78;
            color: ${colors.text};
            padding: 15px 0;
        }

        ol,
        li {
            font-family: ${fontFamily.primary};
            font-size: ${fontSizes.f14};
        }

        li {
            margin-bottom: 8px;
        }

        .wp-block-video {
            video {
                max-width: 100%;
            }
        }

        blockquote {
            margin: 0;

            em {
                font-size: 1.125rem;
                font-weight: 600;
                font-style: italic;
                line-height: 1.33;
                color: ${colors.text};
                margin: 0;
                padding: 15px 0;

                @media screen and (min-width: 992px) {
                    font-size: 2rem;
                }

                & strong {
                    font-family: ${fontFamily.primary};
                }
            }

            strong {
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.44;
                letter-spacing: -1.6px;
                color: ${colors.text};
                margin: 0;

                @media screen and (min-width: 992px) {
                    font-size: 1.5rem;
                }
            }
        }

        figure {
            width: 100%;
            margin: 15px 0;
            text-align: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.44;
            letter-spacing: -1.6px;
            color: ${colors.text};
            margin: 40px 0 20px 0;

            @media screen and (min-width: 992px) {
                font-size: 36px;
            }
        }
        h3,
        h4,
        h5 {
            color: ${colors.text};
            font-size: ${fontSizes.f26};
            line-height: 28px;
            font-weight: ${fontWeight.medium};
        }

        pre {
            background: ${colors.section};
            padding: 10px;
            color: ${colors.text};
            overflow-x: auto; /* Enable horizontal scrolling when content overflows */
            white-space: pre-wrap; /* Preserve line breaks */
            word-wrap: break-word;
        }

        code {
            color: ${colors.text};
        }
        img {
            max-width: 80%;
        }

        .links-wrapper {
            a {
                color: ${colors.mainBlue};
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    `,
);

export const BlurredContent = styled(PostPageContent)(
    () =>
        css`
            overflow: hidden;
        `,
);

export const PostMainCopyright = styled.p`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
`;

export const AuthorWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            border-top: 1px solid ${colors.border};
            margin: 30px 0 74px;
            padding: 28px 0;
            display: flex;
        `,
);

export const AuthorAvatar = styled.img(
    ({ theme: { colors } }) => css`
        border-radius: 50%;
        margin-right: 20px;
        border: 1px solid ${colors.text};
    `,
);

export const AuthorInfoWrapper = styled.div``;

export const AuthorName = styled.p(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text};
        font-size: ${fontSizes.f16};
        line-height: 22px;
        margin-bottom: 8px;
    `,
);

export const AuthorDesctiption = styled.p(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text};
        font-size: ${fontSizes.f14};
        line-height: 16px;
    `,
);

export const PostShareBox = styled(Common.Div)`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
`;

export const PostShareItemTitle = styled.p(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.text};
            font-size: ${fontSizes.f20};
        `,
);

export const PostShareLink = styled.a(
    ({ theme: { colors } }) => css`
        color: ${colors.mainBlue};
    `,
);
