import { yupResolver } from '@hookform/resolvers/yup';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { Checkbox } from 'components/_inputs/Checkbox/Checkbox';
import * as Common from 'components/_universal/Common';
import React, { useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
    BlurredBackground,
    StyledSubmitButton,
    StyledTextInput,
} from 'sections/Blog/ReadMoreForm.styled';
import { StyledLink } from 'sections/Contact/ContactForm/ContactForm.styled';
import {
    COOKIES_EMAIL_FOR_ARTICLE_PROVIDED,
    COOKIES_EMAIL_FOR_ARTICLE,
} from 'shared/localStorage';
import { ROUTE_PRIVACY_POLICY } from 'shared/paths';
import { readMoreBlogForm } from 'shared/validation';
import { B2, B3, H2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const ReadMoreForm = ({
    setFormHeight,
}: {
    setFormHeight: (arg: string) => void;
}) => {
    const isMobile = useBreakpoint('md');
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<FieldValues>({
        mode: 'all',
        resolver: yupResolver(readMoreBlogForm),
    });
    const form = watch();
    const formContainerRef = useRef<HTMLDivElement>(null);
    const currentUrl = window.location.href;

    const onValidSubmit = async () => {
        localStorage.setItem(COOKIES_EMAIL_FOR_ARTICLE_PROVIDED, 'true');
        localStorage.setItem(COOKIES_EMAIL_FOR_ARTICLE, form.email);

        const data = {
            name: form.name,
            email: form.email,
            page: currentUrl,
        };

        try {
            const response = await fetch(
                'https://wordpress.codahead.com/wp-json/v1/blog/subscribe',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            if (typeof window !== 'undefined') {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        setFormHeight(formContainerRef?.current?.offsetHeight + 'px');
    }, [formContainerRef?.current?.offsetHeight]);

    return (
        <BlurredBackground
            flex="column"
            alignItems="center"
            p={isMobile ? 4 : 0}
            ref={formContainerRef}
        >
            <H2 align={isMobile ? 'center' : 'left'}>Sounds interesting?</H2>
            <B3 mb={8} mt={4} align="center">
                You are halfway through the article. If you want to learn more,
                leave us your e-mail address
                <br /> and enjoy unlimited access to our valuable content.
                <br />
                You need to confirm your email!
            </B3>
            <Common.Div w="100%" flex="row" justifyContent="center">
                <Common.Div flex="column" w="100%">
                    <Common.Div flex="row" gap="28px" w="100%">
                        <Common.Div
                            flex={isMobile ? 'column' : 'row'}
                            gap={isMobile ? '12px' : '28px'}
                            w="100%"
                        >
                            <StyledTextInput
                                label="Name*"
                                control={control}
                                errors={errors.name}
                                name="name"
                                type={'text'}
                            />
                            <StyledTextInput
                                label="Email*"
                                control={control}
                                errors={errors.email}
                                name="email"
                                type={'email'}
                            />
                            <StyledSubmitButton
                                mt={isMobile ? 2 : 5}
                                onClick={handleSubmit(
                                    () => onValidSubmit(),
                                    (e) => console.log(e),
                                )}
                            >
                                <B2>send</B2>
                                <Common.Svg src={ArrowSvg} />
                            </StyledSubmitButton>
                        </Common.Div>
                    </Common.Div>
                    <Common.Div flex="row">
                        <Checkbox
                            control={control}
                            isError={!!errors.cb_privacyPolicy}
                            name="cb_privacyPolicy"
                        />
                        <B2>
                            I agree with{' '}
                            <StyledLink
                                href={ROUTE_PRIVACY_POLICY}
                                target="_blank"
                            >
                                Privacy Policy
                            </StyledLink>
                            *
                        </B2>
                    </Common.Div>
                </Common.Div>
            </Common.Div>
        </BlurredBackground>
    );
};
