import { TextInput } from 'components/_inputs/TextInput/TextInput';
import * as Common from 'components/_universal/Common';
import { SubmitButton } from 'sections/Contact/ContactForm/ContactForm.styled';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const BlurredBackground = styled(Common.Div)(
    () =>
        css`
            background: rgba(0, 0, 0, 0.35);
            backdrop-filter: blur(10px);
            margin-bottom: 24px;
            width: 100%;
            padding: 48px 140px;
            position: absolute;
            z-index: 999;

            ${mediaQueries.md} {
                padding: 48px 120px;
            }

            ${mediaQueries.sm} {
                padding: 48px 16px;
            }
        `,
);

export const StyledSubmitButton = styled(SubmitButton)(
    () =>
        css`
            width: 260px;
            height: 57px;

            ${mediaQueries.md} {
                width: 100%;
            }
        `,
);

export const StyledTextInput = styled(TextInput)(
    () =>
        css`
            width: 260px;

            ${mediaQueries.md} {
                width: 100%;
            }
        `,
);
